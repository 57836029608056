<script>
export default {
  name: 'nav',
  computed: { 
    sidebarItems () {
      return [
        {
          _name: 'CSidebarNav',
          _children: [{
              _name: 'CSidebarNavItem',
              name: this.$t('menu.dashboard'),
              to: '/dashboard',
              icon: 'cil-speedometer',
            },
            {
              _name: 'CSidebarNavDropdown',
              name: 'Health',
              // route: '/policies',
              icon: 'cil-heart',
              items: [
                {
                  name: 'Add Enrollees',
                  to: '/policies/buyhealth'
                },
                {
                  name: 'Active Policies',
                  to: '/policies/health'
                },
                {
                  name: 'Inactive Policies',
                  to: '/policies/inactivehealth'
                },
                {
                  name: 'Discontinued Policies',
                  to: '/policies/cancelledhealth'
                },
              ]
            },
            // {
            //   _name: 'CSidebarNavDropdown',
            //   name: 'Device',
            //   // route: '/policies',
            //   icon: 'cil-screen-smartphone',
            //   items: [
            //     {
            //       name: 'Add Enrollees',
            //       to: '/policies/buydevice'
            //     },
            //     {
            //       name: 'Active Policies',
            //       to: '/policies/device'
            //     },
            //     {
            //       name: 'Inactive Policies',
            //       to: '/policies/inactivedevice'
            //     },
            //     {
            //       name: 'Discontinued Policies',
            //       to: '/policies/cancelleddevice'
            //     },
                
            //   ]
            // },
            {
              _name: 'CSidebarNavDropdown',
              name: 'Health/Life',
              // route: '/policies',
              icon: 'cil-people',
              items: [
                {
                  name: 'Add Enrollees',
                  to: '/policies/buylife'
                },
                {
                  name: 'Active Policies',
                  to: '/policies/life'
                },
                {
                  name: 'Inactive Policies',
                  to: '/policies/inactivelife'
                },
                {
                  name: 'Discontinued Policies',
                  to: '/policies/cancelledlife'
                },
              ]
            },
            {
              _name: 'CSidebarNavDropdown',
              name: 'Wallet',
              to: '/wallet',
              icon: 'cil-money',
              items: [
                {
                  name: 'Manage Wallet',
                  to: '/wallet'
                },
                
              ]
            },
            
            // {
            //   _name: 'CSidebarNavItem',
            //   name: 'Settings',
            //   to: '/settings',
            //   icon: 'cil-settings',
            // },
            
            // {
            //   _name: 'CSidebarNavItem',
            //   name: 'Devices',
            //   to: '/devices',
            //   icon: 'cil-money'
            // },
            {
              _name: 'CSidebarNavItem',
              name: 'Logout',
              to: '/app/login',
              icon: 'cil-account-logout'
            },
            
          ]
        },
      ]
    }
  }
}
</script>
