<template>
  <CFooter :fixed="false">
    <!-- <div>
      <a href="https://coreui.io" target="_blank">CoreUI</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} creativeLabs.</span>
    </div>-->
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Copyright</span>
      <span ml-3>GreenLightPlanet Admin Built by PaddyCover</span>
    </div> 
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
